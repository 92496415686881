import React, { useRef } from 'react';
import { ProjectCard } from '../components/ProjectCard';
import spotifyTinder from '../imgs/ionis.png';
import fizzCard from '../imgs/medlaunch.png';
import carta from '../imgs/kth-ubi.png';
import voiceMessage from '../imgs/Vletics-Laptop-View.png';
import personalityPhoto from '../imgs/pfp-professional.png';
import caseStudyBanner from '../imgs/SpotifyDesignCaseBanner.jpg';
import jodtShowcase from '../imgs/jodtshowcase.jpg';
import sunburstFinal from '../imgs/SunburstFinal.png';
import youtubeBanner from '../imgs/YoutubeInEdTech.webp';
import EuropeanTasteTestBanner from '../imgs/EuropeanTasteTestFin.jpg';
import PaypalBanner from '../imgs/PaypalBanner.png';
import logoSquare from '../imgs/logo-square.svg';

export function WorkPage() {
  const productsScrollRef = useRef(null);
  const productWorkScrollRef = useRef(null);
  const myWorkRef = useRef(null);
  const featuredWorkRef = useRef(null); // New ref for Featured Work section

  const experiences = [
    { id: "1", color: "#fffff1", title: "Data Product Intern @ Ionis", tags: ['Product', 'Software', 'Modeling'], company: "Internship", img: spotifyTinder},
    { id: "16", color: "#2a2a2a", title: "Vizgo AI", tags: ['Product', 'Next.js', 'AI'], company: "Founder", img: logoSquare, projectPage: "https://vizgo.dev"},
    { id: "2", color: "#1a2b57", title: "Head of Product @ MedLaunch", tags: ['Engineering', 'Prototyping', 'Leadership'], company: "Club", img: fizzCard},
    { id: "3", color: "#84E7D6", title: "Urban Mobility Initiative", tags: ['UI/UX', 'Product Strategy'], company: "Product Strategy", img: carta},
    { id: "4", color: "#38cb82", title: "Vletics", tags: ['Users', 'Software', 'AI'], company: "Startup Founder", img: voiceMessage},
  ];

  const products = [
    { id: "5", color: "#FFA07A", title: "Jodt: Your Efficient Second Brain", tags: ['UI/UX', 'Mobile App'], company: "Product", img: jodtShowcase, projectPage: "/jodt" },
    { id: "6", color: "#20B2AA", title: "European Taste Test", tags: ['Analytics', 'Dashboard'], company: "Product & Project", img: EuropeanTasteTestBanner, projectPage: "/EuropeanTT" },
    { id: "7", color: "#DDA0DD", title: "How I Redesigned Spotify Social Features", tags: ['Product', 'Data', 'AI'], company: "Product Case", img: caseStudyBanner, projectPage: "/SpotifyDesignCase" },
  ];

  const productWork = [
    { id: "10", color: "#FF6347", title: "How I Redesigned Spotify Social Features", tags: ['Product', 'Data', 'AI'], company: "Product Case", img: caseStudyBanner, projectPage: "/SpotifyDesignCase" },
    { id: "11", color: "#4682B4", title: "YouTube in EdTech", tags: ['EdTech', 'Analysis'], company: "Case Study", img: youtubeBanner, projectPage: "/construction" },
    { id: "12", color: "#32CD32", title: "PayPal Case Study", tags: ['FinTech', 'UX'], company: "Case Study", img: PaypalBanner, projectPage: "/construction" },
  ];

  const featuredWork = [
    { id: "17", color: "#2a2a2a", title: "Vizgo AI", tags: ['Product', 'Next.js', 'AI', 'LLMs'], company: "Product", img: logoSquare, projectPage: "https://vizgo.dev" },
    { id: "13", backgroundImage: caseStudyBanner, title: "How I Redesigned Spotify Social Features", tags: ['Product', 'Data', 'AI'], company: "Product Case", projectPage: "/SpotifyDesignCase" },
    { id: "14", backgroundImage: jodtShowcase, title: "Jodt: Your Efficient Second Brain", tags: ['UI/UX', 'Mobile App'], company: "Product", projectPage: "/jodt" },
  ];

  const scrollToMyWork = () => {
    myWorkRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToFeaturedWork = () => {
    featuredWorkRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="flex-grow overflow-y-auto overflow-x-hidden pt-12 font-['Acorn',sans-serif] font-normal text-[rgb(10,11,87)] bg-[#F5F4EC]">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-6 mb-12">
        {/* Introduction Section */}
        <div className="flex flex-col md:flex-row items-center justify-center mb-12 space-y-6 md:space-y-0 md:space-x-8">
          <div className="flex-1 text-center md:text-left max-w-md">
            <h2 className="text-xl mb-2 font-normal">Hi, I am</h2>
            <h1 className="text-5xl font-semibold mb-3 text-[rgb(10,11,87)]">Sathvik</h1>
            <h3 className="text-2xl font-medium mb-3">Product | Data | Software</h3>
            <ul className="text-base mb-4 font-normal list-disc pl-5 text-left">
              <li>Product & business focused builder passionate about leveraging data, software, and design to create impactful, user-centric solutions.</li>
              <li>Computer Science & Statistics @ University of Illinois at Urbana-Champaign</li>
              <li>Proficient in analyzing complex business data and translating insights into clear, actionable strategies to drive operational improvements and informed decision-making.</li>
            </ul>
            <div className="flex space-x-4">
              <button 
                onClick={scrollToFeaturedWork}
                className="bg-[rgb(10,11,87)] hover:bg-opacity-80 text-white font-semibold py-2 px-4 rounded text-sm"
              >
                Featured Work
              </button>
              <button 
                onClick={scrollToMyWork}
                className="bg-[rgb(10,11,87)] hover:bg-opacity-80 text-white font-semibold py-2 px-4 rounded text-sm"
              >
                View My Work
              </button>
            </div>
          </div>
          <div className="flex-shrink-0">
            <img src={personalityPhoto} alt="Sathvik" className="w-64 h-64 object-cover rounded-lg" />
          </div>
        </div>

        {/* Featured Work section */}
        <div ref={featuredWorkRef} className="mb-12">
          <h1 className="text-3xl font-semibold mb-6">Featured Work</h1>
          <div className="flex overflow-x-auto pb-4 space-x-6">
            {featuredWork.map((project) => (
              <div key={project.id} className="flex-shrink-0 w-64">
                <ProjectCard 
                  {...project} 
                  isExperience={false} 
                  isCompact={false} 
                  sizeLarge={true}
                  backgroundImage={project.backgroundImage}
                />
              </div>
            ))}
          </div>
        </div>

        {/* My Work section */}
        <div ref={myWorkRef} className="my-work-section">
          <h1 className="text-3xl font-semibold mb-6">My Work</h1>
          
          <div className="flex flex-col lg:flex-row gap-8">
            {/* Left Side: Experience */}
            <div className="w-full lg:w-[45%]">
              <h2 className="text-xl font-medium mb-3">Experience</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {experiences.map((project) => (
                  <div key={project.id} className="flex justify-start">
                    <ProjectCard {...project} isExperience={true} isCompact={true} sizeLarge={false} />
                  </div>
                ))}
              </div>
            </div>

            {/* Right Side: Products & Product Work */}
            <div className="w-full lg:w-[55%] flex flex-col gap-8">
              {/* Products Section */}
              <div>
                <h2 className="text-xl font-medium mb-2">Projects</h2>
                <div className="overflow-x-auto scrollbar-hide" ref={productsScrollRef}>
                  <div className="flex gap-3 pb-2" style={{ width: 'max-content' }}>
                    {products.map((project) => (
                      <div key={project.id} className="w-32 flex-shrink-0">
                        <ProjectCard {...project} isExperience={false} isCompact={true} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Product Work Section */}
              <div>
                <h2 className="text-xl font-medium mb-2">Case Studies</h2>
                <div className="overflow-x-auto scrollbar-hide" ref={productWorkScrollRef}>
                  <div className="flex gap-3 pb-2" style={{ width: 'max-content' }}>
                    {productWork.map((project) => (
                      <div key={project.id} className="w-32 flex-shrink-0">
                        <ProjectCard {...project} isExperience={false} isCompact={true} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}